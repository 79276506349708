let element = require('element-ui')


const formatTime = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    // const second = date.getSeconds()
    return `${[year, month, day].join('-')} ${[hour, minute].map(formatNumber).join(':')}`
}
const formatTime2 = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${[year, month, day].join('-')}`
}
const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : `0${n}`
}
const formatMinTime = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return [year, month, day].join('-');
}
//全局消息弹窗
const messageBox = (msg, title, type) => {
    element.MessageBox.confirm(msg, '错误', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: type
    }).then(r => console.log(r));
}
const errorMsg = msg => {
    messageBox(msg, "错误", 'error')
}
const warningMsg = msg => {
    messageBox(msg, "警告", 'warning')
}
const sucMsg = msg => {
    messageBox(msg, "成功", 'success')
}
/*const getWeek = () =>{
  const weekDay = ["星期天", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
  let time = new Date();
  let week = weekDay[time.getDay()];
  return week;
}
const getMonth = () =>{
  const monthList = ["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月",];
  let time = new Date();
  let month = monthList[time.getMonth()];
  return month;
}
const remainDay = () =>{
  let curDate = new Date();
  let curMonth = curDate.getMonth();
  curDate.setMonth(curMonth + 1);
  curDate.setDate(0);
  return curDate.getDate()-new Date().getDate();
}*/
module.exports = {
    formatTime,
    formatMinTime,
    formatTime2,
    errorMsg,
    warningMsg,
    sucMsg,
    messageBox
    /*  getWeek,
      getMonth,
      remainDay*/
}
