import axios from "axios";
import {Message, MessageBox, Loading} from 'element-ui'

let util = require("../assets/js/util.js")

export function request(config) {
    const instance = axios.create({
        //根路径
        //本地
        // baseURL: 'http://localhost:8088/',
        //发布上线
        baseURL:'https://service.coclyun.top/housev2/',
        //其它电脑
        // baseURL: 'http://192.168.68.246:8088/',
        timeout: 10000,
    })
    //开始加载动画
    let loading;

    function startLoading() {
        loading = Loading.service({
            lock: true, //是否锁定
            text: '拼命加载中...',//加载中需要显示的文字
            /* background:'rgba(0,0,0,0.2)',//背景颜色*/
        });
    }

    //结束加载动画
    function endLoading() {
        loading.close();
    }

    //axios 拦截器
    //请求拦截
    instance.interceptors.request.use(config => {
        startLoading();//请求时的加载动画
        //每个请求头文件加入 token
        config.headers.token = window.localStorage.getItem('token');
        return config
    }, err => {
        console.log(err)
    })
    //响应拦截
    instance.interceptors.response.use(res => {
        console.log(res)
        if ('token' in res.headers) {
            window.localStorage.setItem('token', res.headers.token);
        }
        if (res.data.status !== 200) {
            if (res.data.status===500){
                util.errorMsg(res.data.status + " : 服务器内部错误")
            }else{
                util.errorMsg(res.data.status + " : " + res.data.desc)
            }
        }
        setTimeout(res => {
            endLoading();//结束加载动画
        }, 251)
        return res.data
    }, err => {
        endLoading()//结束动画
        if (err.toString().indexOf("Request failed with status code 401") >= 0) {
            MessageBox.confirm('您已被登出', '登出提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(r => {
                let href = window.location.href;
                window.location.href = href.substring(0, href.indexOf("/")) + "/login"
            });
        } else {
            MessageBox.confirm('服务器连接失败', '错误', {
                confirmButtonText: '刷新页面',
                cancelButtonText: '取消',
                type: 'error'
            }).then(r => {
                window.location.reload()
            });
        }

    })
    // 发送真正的网络请求
    return instance(config)
}
